// Import packages
import React from "react";
import {Route, Routes,Navigate} from "react-router-dom";
import Layout from "../containers/Layout";

// Import utils

// Import pages
import Homepage from "../containers/homepage/Homepage";
import Contact from "../containers/contact/Contact";
import Fitness from "../containers/fitness/Fitness";
import FitnessSection from "../containers/fitness/section/FitnessSection";
import Spa from "../containers/spa/Spa";
import Cafe from "../containers/cafe/Cafe";
import About from "../containers/about/About";
import Events from "../containers/events/Events";
import EventDetail from "../containers/events/eventDetail/EventDetail";
import ArticleDetail from "../containers/events/articleDetail/ArticleDetail";
import NotFound from "../containers/notFound/NotFound";
import Pool from "../containers/pool/Pool";
import Privacy from "../containers/priviesPolice/Privacy";
import {useReactiveVar} from "@apollo/client";
import {appLanguage} from "../graphql/reactive-variables";
import Regulation from "../containers/regulation/Regulation";
import Packages from "../containers/packages/Packages";


export default function RoutesBlocks() {
    const language = useReactiveVar(appLanguage)

        return <Layout>
            <Routes>
                <Route path="/" element={ <Navigate to={`/home/${language}`} replace />} />
                <Route path={`/home/${language}`} element={<Homepage/>}/>
                <Route path={`/fitness/${language}`} element={<Fitness/>}/>
                <Route path={`/fitness/:id/${language}`} element={<FitnessSection/>}/>
                <Route path={`/spa/${language}`} element={<Spa/>}/>
                <Route path={`/cafe/${language}`} element={<Cafe/>}/>
                <Route path={`/about/${language}`} element={<About/>}/>
                <Route path={`/news/${language}`} element={<Events/>}/>
                <Route path={`/event/detail/:id/${language}`} element={<EventDetail/>}/>
                <Route path={`/article/detail/:id/${language}`} element={<ArticleDetail/>}/>
                <Route path={`/contact/${language}`} element={<Contact/>}/>
                <Route path={`/pool/${language}`} element={<Pool/>}/>
                <Route path={`/regulation/${language}`} element={<Regulation/>}/>
                <Route path={`/packages/${language}`} element={<Packages/>}/>
                <Route path={`/privacy/${language}`} element={<Privacy/>}/>
                <Route path={`/404/${language}`} element={<NotFound/>}/>
                <Route path="*" element={ <NotFound/>} />
                {/*<Route path="*" element={ <Navigate to={`/404/${language}`} replace />} />*/}
            </Routes>
        </Layout>
}


