import { useEffect, useState } from "react";

//Assets
import '../assets/styles/layout.scss'

//Components
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

import MobileMenu from "../components/mobileMenu/MobileMenu";

//Hooks
import { useTranslations } from "../graphql/query.hooks/translation.hook";
import { Languages } from "../utils/constanats";
import { useReactiveVar } from "@apollo/client";
import { appLanguage } from "../graphql/reactive-variables";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";


function Layout (props) {
    const { children } = props
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const language = useReactiveVar(appLanguage)
    const location = useLocation()
    const navigate = useNavigate();
    const [memberships, setMemberships] = useState(false);

    moment.locale(language === Languages.hy ? "hy-am" : "en")

    function toggleMobileMenu () {
        setMobileMenuOpen(!mobileMenuOpen)
    }

    const translations = useTranslations()
    // useEffect(() => {
    //     const storageLanguage = localStorage.getItem('language')
    //     if (!storageLanguage) {
    //         localStorage.setItem('language', Languages.hy);
    //     }
    // }, [])

    useEffect(() => {
        // execute on location change
        const pathname = location.pathname;
        const currLang = pathname?.split('/').at(-1);
        if (currLang === Languages.en || currLang === Languages.hy) {
            if (currLang !== language) {
                const pathnameWithoutLang = pathname?.slice(0, -currLang.length)
                navigate(pathnameWithoutLang + language, { replace: true })
            }
        } else {
            // navigate(pathname.endsWith('/') ? pathname + language : `${pathname}/${language}`, { replace: true })
            navigate( `/home/${language}`, { replace: true })
        }
    }, [location]);

    function toggleMembershipBlock(){
        if(location.pathname.includes('home')){
            window.scrollTo(0, 2760)
        }else {
            navigate( `/home/${language}`, { replace: true })
            setMemberships(true)
        }
    }

    useEffect(() => {
        if(memberships && location.pathname.includes('home')){
            window.scrollTo(0, 2760)
        }
    }, [location]);
    return <div className={'layout-wrapper'}>

        <Header mobileMenuOpen={mobileMenuOpen}
                translations={translations}
                setMemberships={setMemberships}
                toggleMembershipBlock={toggleMembershipBlock}
                toggleMobileMenu={toggleMobileMenu}/>
        <MobileMenu className={mobileMenuOpen ? 'expand_on' : ''}
                    mobileMenuOpen={mobileMenuOpen}
                    translations={translations}
                    toggleMembershipBlock={toggleMembershipBlock}
                    toggleMobileMenu={toggleMobileMenu}/>
        <div>
            {children}
        </div>
        <Footer translations={translations}
                mobileMenuOpen={mobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}/>
    </div>
}

export default Layout
