import './membershipBlock.scss'
import { Tabs } from "antd";
import {useRef, useState} from "react";
import { PackageTypes } from "../../../utils/constanats";
import { usePackages } from "../../../graphql/query.hooks/package.hook";
import PackageCard from "../../uiElements/cards/packageCard/PackageCard";
import { Swiper, SwiperSlide } from 'swiper/react';
import {MembershipArrLeftIcon, MembershipArrRightIcon} from "../../../assets/images";
import { GetUrlWithLocal } from "../../../utils/getUrlWithLocal";
import { Link, useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { appLanguage } from "../../../graphql/reactive-variables";


function MembershipBlock (props) {
    const { translations } = props
    const [activeTab, setActiveTab] = useState(PackageTypes.Package)
    const { data: packages } = usePackages(activeTab)
    const sliderRef = useRef(null);
    const navigate = useNavigate();
    const language = useReactiveVar(appLanguage)

    const onChange = (key) => {
        setActiveTab(key)
    };
    const items = [
        {
            key: PackageTypes.Package,
            label: `${translations?.homepage_membership_title}`,
        },
        {
            key: PackageTypes.Paid,
            label: `${translations?.homepage_addition_title}`,
        }
    ];

    const handlePrev = () => {
        if (!sliderRef.current) return;
        sliderRef.current?.swiper?.slidePrev();
    };

    const handleNext = () => {
        if (!sliderRef.current) return;
        sliderRef.current?.swiper?.slideNext();
    };

    console.log(packages,'packages')

    return <section className={'membership-wrapper'} id={'membership'}>
        <div className={'membership-block-header'}>
            <div className={'membership-block-title'}>
                {translations?.homepage_membership_block_title}
            </div>
            <div className={'membership-block-description'}>
                {translations?.homepage_membership_block_description}
            </div>
        </div>
        <div className={'membership-blocks'}>
            <Tabs value={activeTab} items={items} onChange={onChange}/>
            <div className={'membership-block'}>
                {
                    packages?.length > 3 ? <div className={'membership-arr-block'}>
                        <button className={'prev_btn'}
                                onClick={handlePrev}>
                            <MembershipArrLeftIcon/>
                        </button>
                        <button className={'next_btn'}
                                onClick={handleNext}>
                            <MembershipArrRightIcon/>
                        </button>
                        <button className={'see_all_btn'}
                        onClick={()=>{
                            navigate(`/packages/${language}`)}
                        }>
                            {translations?.home_packages_show_all || ""} <MembershipArrRightIcon/>
                        </button>
                    </div> : ''
                }
                <Swiper
                    className={'membership-slider'}
                    spaceBetween={'2%'}
                    slidesPerView={1.2}
                    ref={sliderRef}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)} //Callback that receives Swiper instance
                    breakpoints={{
                        600: {
                            slidesPerView: 2,
                        },
                        1050: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {
                        packages?.map(item => {
                            const data = {
                                id: item.id,
                                name: item.name,
                                title: item.title,
                                information: item.web_information,
                                description: item.web_description,
                                details: item.web_details,
                                duration_options:item.duration_options,
                            }
                            return <SwiperSlide key={item?.id}>
                                <div className={'package-card-slider-wrapper'}>
                                    <PackageCard key={item?.id} data={data} translations={translations}/>
                                </div>
                            </SwiperSlide>
                        })
                    }
                </Swiper>
            </div>
        </div>
    </section>
}

export default MembershipBlock
